import * as types from "./types";
import {
  getUsers,
  getCompanies,
  createUser,
  registerUser,
  confirmUserEmail,
  confirmUserEmailSend,
  updateUser,
  deleteUser,
  getUser,
} from "./services";

export async function getUsersAction({ commit }) {
  commit(types.PROCESSING_USERS, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await getUsers();
    commit(types.GET_USERS_DATA, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_USERS, false);
  }

  return state;
}

export async function getCompaniesAction({ commit }) {
  commit(types.PROCESSING_USERS, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await getCompanies();
    commit(types.GET_COMPANY_USERS_DATA, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_USERS, false);
  }

  return state;
}

export async function getUserAction({ commit }, payload) {
  commit(types.PROCESSING_USER, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await getUser(payload);
    commit(types.GET_USER_DATA, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_USER, false);
  }

  return state;
}

export async function createUserAction({ commit }, payload) {
  commit(types.PROCESSING_USER, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await createUser(payload.userModel, payload.roles);
    commit(types.CREATE_USER, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_USER, false);
  }

  return state;
}

export async function registerUserAction({ commit }, payload) {
  commit(types.PROCESSING_USER, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await registerUser(payload);
    commit(types.REGISTER_USER, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_USER, false);
  }

  return state;
}

export async function confirmUserEmailAction({ commit }, payload) {
  commit(types.PROCESSING_USER, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await confirmUserEmail(payload.code, payload.uid);
    commit(types.CONFIRM_USER_EMAIL, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_USER, false);
  }

  return state;
}

export async function resendEmailConfirmationAction({ commit }, payload) {
  commit(types.PROCESSING_USER, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await confirmUserEmailSend(payload);
    state.success = data;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_USER, false);
  }

  return state;
}

export async function updateUserAction({ commit }, payload) {
  commit(types.PROCESSING_USER, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await updateUser(payload);
    commit(types.UPDATE_USER, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_USER, false);
  }

  return state;
}

export async function deleteUserAction({ commit }, payload) {
  commit(types.PROCESSING_USER, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await deleteUser(payload);
    commit(types.DELETE_USER, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_USER, false);
  }

  return state;
}
