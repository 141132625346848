import * as types from "./types";
import {
  getTermins,
  getTermin,
  createTermin,
  deleteTermin,
  updateTermin,
} from "./services";

export async function getTerminsAction({ commit }, payload) {
  commit(types.PROCESSING_TERMINS, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await getTermins(
      payload.providerId,
      payload.startDate,
      payload.endDate
    );
    commit(types.GET_TERMINS, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_TERMINS, false);
  }

  return state;
}

export async function getTerminAction({ commit }, payload) {
  commit(types.PROCESSING_TERMIN, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await getTermin(payload);
    commit(types.GET_TERMIN, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_TERMIN, false);
  }

  return state;
}

export async function createTerminAction({ commit }, payload) {
  commit(types.PROCESSING_TERMIN, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await createTermin(payload);
    commit(types.CREATE_TERMIN, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_TERMIN, false);
  }

  return state;
}

export async function updateTerminAction({ commit }, payload) {
  commit(types.PROCESSING_TERMIN, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await updateTermin(payload);
    commit(types.UPDATE_TERMIN, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_TERMIN, false);
  }

  return state;
}

export async function deleteTerminAction({ commit }, payload) {
  commit(types.PROCESSING_TERMIN, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await deleteTermin(payload);
    commit(types.DELETE_TERMIN, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_TERMIN, false);
  }

  return state;
}
