import axios from "axios";
import { interceptorsInit } from "./interceptors";

const debug = process.env.NODE_ENV !== "production";

const baseURL = debug
  ? "https://localhost:5001/api"
  : "https://de.kasalo.app/api/";

let api = axios.create({ baseURL });
api = interceptorsInit(api);

export default api;
