import Vue from "vue";
import Vuex from "vuex";

import authModule from "./auth";
import userModule from "./user";
import commonModule from "./common";
import beautyServiceModule from "./beauty-service";
import serviceProviderModule from "./service-provider";
import beautyServiceProvidedModule from "./beauty-service-provided";
import workScheduleModule from "./work-schedule";
import terminModule from "./termin";
import personalInfoModule from "./personal-info";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    authModule,
    userModule,
    commonModule,
    beautyServiceModule,
    serviceProviderModule,
    beautyServiceProvidedModule,
    workScheduleModule,
    terminModule,
    personalInfoModule,
  },
});
