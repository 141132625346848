import * as types from "./types";

const mutations = {
  [types.PROCESSING_TERMINS](state, value) {
    state.processingTermins = value;
  },
  [types.GET_TERMINS](state, value) {
    state.termins = value;
  },
  [types.PROCESSING_TERMIN](state, value) {
    state.processingTermin = value;
  },
  [types.GET_TERMIN](state, value) {
    state.termin = value;
  },
  [types.UPDATE_TERMIN](state, value) {
    state.termin = value;
    if (state.termins) {
      let idx = state.termins
        .map(function (e) {
          return e.id;
        })
        .indexOf(value.id);
      state.termins.splice(idx, 1);
      state.termins.push(Object.assign({}, value));
    }
  },
  [types.CREATE_TERMIN](state, value) {
    state.termin = value;
    if (state.termins) {
      let copy = Object.assign({}, value);
      state.termins.push(copy);
    }
  },
  [types.DELETE_TERMIN](state, value) {
    state.TERMIN = value;
    if (state.termins) {
      let idx = state.termins
        .map(function (e) {
          return e.id;
        })
        .indexOf(value.id);
      state.termins.splice(idx, 1);
    }
  },
};

export default mutations;
