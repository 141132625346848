import * as types from "./types";
import {
  getServices,
  createService,
  updateService,
  deleteService,
  getService,
} from "./services";

export async function getServicesAction({ commit }, payload) {
  commit(types.PROCESSING_SERVICES_PROVIDED, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await getServices(payload);
    commit(types.GET_BEAUTY_SERVICES_PROVIDED, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_SERVICES_PROVIDED, false);
  }

  return state;
}

export async function getServiceAction({ commit }, payload) {
  commit(types.PROCESSING_SERVICE_PROVIDED, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await getService(payload);
    commit(types.GET_BEAUTY_SERVICE_PROVIDED, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_SERVICE_PROVIDED, false);
  }

  return state;
}

export async function createServiceAction({ commit }, payload) {
  commit(types.PROCESSING_SERVICE_PROVIDED, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await createService(payload);
    commit(types.CREATE_BEAUTY_SERVICE_PROVIDED, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_SERVICE_PROVIDED, false);
  }

  return state;
}

export async function updateServiceAction({ commit }, payload) {
  commit(types.PROCESSING_SERVICE_PROVIDED, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await updateService(payload);
    commit(types.UPDATE_BEAUTY_SERVICE_PROVIDED, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_SERVICE_PROVIDED, false);
  }

  return state;
}

export async function deleteServiceAction({ commit }, payload) {
  commit(types.PROCESSING_SERVICE_PROVIDED, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await deleteService(payload);
    commit(types.DELETE_BEAUTY_SERVICE_PROVIDED, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_SERVICE_PROVIDED, false);
  }

  return state;
}
