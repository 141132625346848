import * as types from "./types";

const mutations = {
  [types.PROCESSING_SCHEDULE](state, value) {
    state.processing = value;
  },
  [types.GET_SCHEDULE](state, value) {
    state.schedule = value;
  },
  [types.GET_CURRENT_DAY_SCHEDULE](state, value) {
    state.currentDay = value;
  },
  [types.SET_SCHEDULE](state, value) {
    state.lastDay = value;
    // let idx = state.schedule
    //   .map(function (e) {
    //     return e.id;
    //   })
    //   .indexOf(value.id);
    // if (idx != -1) state.schedule.splice(idx, 1);
    // state.schedule.push(Object.assign({}, value));
  },
  [types.UNSET_SCHEDULE](state, value) {
    state.lastDay = value;
    // let idx = state.schedule
    //   .map(function (e) {
    //     return e.id;
    //   })
    //   .indexOf(value.id);
    // state.schedule.splice(idx, 1);
  },
};

export default mutations;
