import * as types from "./types";
import { jwtDecode } from "jwt-decode";

const mutations = {
  [types.LOGIN_USER](state, token) {
    state.signInState.token = token;
    const loginClaim = jwtDecode(token);
    claimToState(state, loginClaim);
    localStorage.setItem("token", token);
  },
  [types.LOCAL_STORAGE_TOKEN_LOGIN](state, token) {
    state.signInState.token = token;
    const loginClaim = jwtDecode(token);
    claimToState(state, loginClaim);
  },
};

export default mutations;

function claimToState(state, claim) {
  state.signInState.sub = parseInt(claim.sub);
  state.signInState.email = claim.email;
  state.signInState.name = claim.name;
  state.signInState.exp = claim.exp;
  state.signInState.roles = claim.roles.split("|");
}
