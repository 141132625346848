<template>
  <v-card v-if="user">
    <v-card-title>Prima! Dein Kasalo Account wurde erstellt.</v-card-title>
    <v-card-subtitle class="font-weight-medium text-left">
      Nur noch einen Schritt und du hast es geschafft. Bestätige deine
      Registrierung mit dem Kasalo Code den wir an
      <strong>{{ user.email }}</strong> gesendet haben.
    </v-card-subtitle>
    <v-card-text>
      <v-otp-input autofocus v-model="code" focus-all></v-otp-input>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="d-flex align-center justify-center">
      <v-btn
        :disabled="!code || code.length < 6"
        small
        outlined
        color="success"
        dense
        @click="confirmEmail"
      >
        Verifizieren
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "EmailConfirmCard",

  data() {
    return {
      code: null,
    };
  },

  computed: {
    ...mapGetters("userModule", {
      processingUser: "processingUser",
      user: "user",
    }),
  },

  methods: {
    ...mapActions("userModule", ["confirmUserEmailAction"]),

    async confirmEmail() {
      const payload = {
        code: this.code,
        uid: this.user.id,
      };
      let result = await this.confirmUserEmailAction(payload);
      if (result.success) {
        this.$emit("confirmed");
      }
    },
  },
};
</script>
