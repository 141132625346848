const getters = {
  schedule: (state) => state.schedule,
  workingDays: (state) =>
    state.schedule.map(function (e) {
      return e.dayNumber;
    }),
  lastDay: (state) => state.lastDay,
  currentDay: (state) => state.currentDay,
  processing: (state) => state.processing,
};

export default getters;
