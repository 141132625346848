<template>
  <v-app>
    <navigation-bar @show-navigation="toggleNavigation" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavigationBar from "@/components/NavigationBar";
export default {
  components: {
    NavigationBar,
  },
  name: "App",

  data() {
    return {};
  },

  methods: {
    isProduction() {
      return process.env.NODE_ENV === "production";
    },

    toggleNavigation() {
      this.$showNavigation = !this.$showNavigation;
    },
  },
};
</script>

<style>
.wb-normal {
  word-break: normal;
}

.no-scroll .v-data-table__wrapper {
  overflow-x: hidden;
}

.v-data-table__mobile-row__cell {
  max-width: calc(100vw * 0.5) !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
</style>
