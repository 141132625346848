import api from "@/api/api-config";

export async function getUsers() {
  return await api.get(`user`);
}

export async function getCompanies() {
  return await api.get(`user/role/Company`);
}

export async function getUser(userId) {
  return await api.get(`user/${userId}`);
}

export async function createUser(data, roles) {
  return await api.post(`user/${roles}`, data);
}

export async function registerUser(data) {
  return await api.post(`user/register`, data);
}

export async function confirmUserEmail(token, id) {
  return await api.get(`user/confirm-email/token/${token}/${id}`);
}

export async function confirmUserEmailSend(id) {
  return await api.get(`user/confirm-email/token-send/${id}`);
}

export async function updateUser(data) {
  return await api.put(`user`, data);
}

export async function deleteUser(userId) {
  return await api.delete(`user/${userId}`);
}
