import * as types from "./types";

const mutations = {
  [types.PROCESSING_PROVIDERS](state, value) {
    state.processingProviders = value;
  },
  [types.GET_SERVICE_PROVIDERS](state, value) {
    state.providers = value;
  },
  [types.PROCESSING_PROVIDER](state, value) {
    state.processingProvider = value;
  },
  [types.GET_SERVICE_PROVIDER](state, value) {
    state.provider = value;
  },
  [types.UPDATE_SERVICE_PROVIDER](state, value) {
    state.provider = value;
    if (state.providers) {
      let idx = state.providers
        .map(function (e) {
          return e.id;
        })
        .indexOf(value.id);
      state.providers.splice(idx, 1);
      state.providers.push(Object.assign({}, value));
    }
  },
  [types.CREATE_SERVICE_PROVIDER](state, value) {
    state.provider = value;
    if (state.providers) {
      let copy = Object.assign({}, value);
      state.providers.push(copy);
    }
  },
  [types.DELETE_SERVICE_PROVIDER](state, value) {
    state.provider = value;
    if (state.providers) {
      let idx = state.providers
        .map(function (e) {
          return e.id;
        })
        .indexOf(value.id);
      state.providers.splice(idx, 1);
    }
  },
};

export default mutations;
