import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;
Vue.prototype.window = window;
Vue.prototype.navigator = navigator;
const globalState = Vue.observable({
  showNavigation: false,
});
Object.defineProperty(Vue.prototype, "$showNavigation", {
  get() {
    return globalState.showNavigation;
  },
  set(value) {
    globalState.showNavigation = value;
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
