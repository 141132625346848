import * as types from "./types";

const mutations = {
  [types.PROCESSING_SERVICES_PROVIDED](state, value) {
    state.processingServices = value;
  },
  [types.GET_BEAUTY_SERVICES_PROVIDED](state, value) {
    state.services = value;
  },
  [types.PROCESSING_SERVICE_PROVIDED](state, value) {
    state.processingService = value;
  },
  [types.GET_BEAUTY_SERVICE_PROVIDED](state, value) {
    state.service = value;
  },
  [types.UPDATE_BEAUTY_SERVICE_PROVIDED](state, value) {
    state.service = value;
    let idx = state.services
      .map(function (e) {
        return e.id;
      })
      .indexOf(value.id);
    state.services.splice(idx, 1);
    state.services.push(Object.assign({}, value));
  },
  [types.CREATE_BEAUTY_SERVICE_PROVIDED](state, value) {
    state.service = value;
    let copy = Object.assign({}, value);
    state.services.push(copy);
  },
  [types.DELETE_BEAUTY_SERVICE_PROVIDED](state, value) {
    state.service = value;
    let idx = state.services
      .map(function (e) {
        return e.id;
      })
      .indexOf(value.id);
    state.services.splice(idx, 1);
  },
};

export default mutations;
