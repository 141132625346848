export const PROCESSING_USERS = "PROCESSING_USERS";
export const PROCESSING_USER = "PROCESSING_USER";
export const GET_USERS_DATA = "GET_USERS_DATA";
export const GET_COMPANY_USERS_DATA = "GET_COMPANY_USERS_DATA";
export const GET_USER_DATA = "GET_USER_DATA";
export const CREATE_USER = "CREATE_USER";
export const REGISTER_USER = "REGISTER_USER";
export const CONFIRM_USER_EMAIL = "CONFIRM_USER_EMAIL";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
