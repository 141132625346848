<template>
  <v-container fluid>
    <v-row v-show="processingCommon || processingUser">
      <v-col sm="4"></v-col>
      <v-col cols="12" sm="4">
        <v-skeleton-loader
          height="100%"
          type="article, actions"
        ></v-skeleton-loader>
      </v-col>
      <v-col sm="4"></v-col>
    </v-row>
    <v-row v-show="verified" class="no-gutters">
      <v-col sm="4"></v-col>
      <v-col cols="12" sm="4">
        <v-banner class="py-2" stacked="true" lines="two">
          <template v-slot:icon>
            <v-icon large color="success">mdi-check-circle-outline</v-icon>
          </template>
          <h3>Profil verifiziert</h3>
          Glückwunsch, dein Profil wurde erfolgreich aktiviert!
          <template v-slot:actions>
            <router-link to="/login">
              <v-btn small outlined dense color="success">
                Logge dich jetzt ein
              </v-btn>
            </router-link>
          </template>
        </v-banner>
      </v-col>
      <v-col sm="4"></v-col>
    </v-row>
    <v-row
      v-show="!verified && !(processingCommon || processingUser)"
      class="text-center no-gutters"
    >
      <v-col sm="4"></v-col>
      <v-col cols="12" sm="4">
        <v-form
          v-model="valid"
          @submit.prevent="registerUser"
          class="text-center"
        >
          <h1>Registrieren eines neuen Kontos</h1>
          <v-divider class="pb-5"></v-divider>
          <v-chip-group v-model="userData.roles" selected-class="text-green">
            <div class="w-100 justify-center pb-5">
              <v-chip
                v-for="role in commonData.roles"
                :key="role.id"
                :value="role.name"
                :disabled="verification"
                filter
              >
                {{ role.name }}
              </v-chip>
            </div>
          </v-chip-group>
          <v-text-field
            v-model="userData.email"
            :rules="emailRules"
            label="Email*"
            dense
            prepend-inner-icon="mdi-email-outline"
            required
            :disabled="verification"
          ></v-text-field>
          <v-text-field
            v-model="userData.userName"
            :rules="usernameRules"
            label="Nutzername*"
            prepend-inner-icon="mdi-account-outline"
            dense
            required
            :disabled="verification"
          ></v-text-field>
          <v-text-field
            v-model="userData.password"
            :rules="passwordRules"
            label="Passwort"
            dense
            prepend-inner-icon="mdi-lock-outline"
            required
            type="password"
            :disabled="verification"
          ></v-text-field>
          <v-text-field
            v-model="passwordConfirm"
            :rules="passwordRules && passwordConfirmRules"
            label="Passwortbestätigung"
            dense
            prepend-inner-icon="mdi-lock-outline"
            required
            type="password"
            :disabled="verification"
          ></v-text-field>
          <v-btn
            v-if="!verification"
            :disabled="!valid || !userData.roles"
            type="submit"
            small
            outlined
            color="success"
            dense
          >
            Erstellen
          </v-btn>
          <v-divider class="mt-5 pb-5"></v-divider>
          <email-confirm-card
            v-show="verification"
            @confirmed="confirmEmail"
          ></email-confirm-card>
        </v-form>
      </v-col>
      <v-col sm="4"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EmailConfirmCard from "../components/user/EmailConfirmCard.vue";

export default {
  name: "RegisterView",

  components: {
    EmailConfirmCard,
  },

  data() {
    return {
      valid: null,
      userData: {
        roles: null,
        email: null,
        userName: null,
        password: null,
      },
      passwordConfirm: null,
      roleRules: [(v) => !!v || "Rolle ist erforderlich!"],
      usernameRules: [
        (v) => !!v || "Benutzername wird benötigt!",
        (v) =>
          (v && v.length <= 120) ||
          "Der Benutzername muss weniger als 120 Zeichen lang sein!",
      ],
      emailRules: [
        (v) => !!v || "E-Mail ist erforderlich!",
        (v) =>
          /^([AÄĄBCĆDEĘFGHIJKLŁMNŃOÖÓPQRSŚTUÜVWXYZŹŻßaäąbcćdeęfghijklłmnńoöópqrsśtuüvwxyzźżß0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(
            v
          ) || "Muss 6 Zeichen lang sein!",
      ],
      passwordRules: [
        (v) =>
          !v ||
          v.toString().length < 1 ||
          v.toString().length > 5 ||
          "Muss 6 Zeichen lang sein!",
      ],
      passwordConfirmRules: [
        (v) =>
          !v ||
          v.toString() === this.userData.password ||
          "Die Passwörter stimmen nicht überein!",
      ],
      verification: false,
      code: null,
      verified: false,
    };
  },

  computed: {
    ...mapGetters("userModule", {
      processingUser: "processingUser",
      user: "user",
    }),
    ...mapGetters("commonModule", {
      processingCommon: "processing",
      commonData: "data",
    }),
  },

  async mounted() {
    await this.getDataAction();
  },

  methods: {
    ...mapActions("userModule", [
      "registerUserAction",
      "confirmUserEmailAction",
    ]),
    ...mapActions("commonModule", ["getDataAction"]),

    async registerUser() {
      const payload = Object.assign({}, this.userData);
      let result = await this.registerUserAction(payload);
      if (result.success) {
        this.verification = true;
      }
    },

    confirmEmail() {
      this.verified = true;
    },
  },
};
</script>

<style>
.w-100 {
  width: 100% !important;
}
</style>
