import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import RegisterView from "../views/RegisterView.vue";

import { authGuard } from "@/store/auth/auth.guard";
import {
  isTokenFromLocalStorageValid,
  isCustomer,
  isMaster,
  isCompany,
  isAdmin,
  authRoute,
} from "@/auth/auth.service";

Vue.use(VueRouter);

const routes = [
  {
    name: "login",
    path: "/login",
    component: () => import("@/auth/views/LoginView.vue"),
    meta: {
      authRequired: false,
    },
    beforeEnter: async (to, from, next) => {
      const valid = await isTokenFromLocalStorageValid();
      if (valid) {
        next("/startseite");
      } else {
        next();
      }
    },
  },
  {
    path: "/logout",
    beforeEnter() {
      localStorage.clear();
      window.location.href = "/";
    },
  },
  {
    path: "/startseite",
    name: "home",
    component: HomeView,
    meta: {
      authRequired: false,
    },
    beforeEnter: (to, from, next) => {
      authRoute(to, from, next);
    },
  },
  {
    path: "/Registrierung",
    name: "register",
    component: RegisterView,
    meta: {
      authRequired: false,
    },
    beforeEnter: (to, from, next) => {
      authRoute(to, from, next);
    },
  },
  {
    path: "/admin/",
    component: () => import("@/views/Admin"),
    meta: {
      authRequired: true,
      authorize: ["Admin", "Developer"],
    },
    beforeEnter: async (to, from, next) => {
      const valid = isAdmin();
      if (valid) {
        next();
      } else {
        next("/startseite");
      }
    },
    children: [
      {
        path: "",
        component: () => import("@/views/Common/UserView"),
      },
      {
        path: "Kontoliste",
        component: () => import("@/views/Admin/UserListView"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "Sch%C3%B6nheitsdienstleistungen",
        component: () => import("@/views/Admin/BeautyServiceListView"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "Dienstleister",
        component: () => import("@/views/Admin/ServiceProviderListView"),
        meta: {
          authRequired: true,
        },
      },
    ],
  },
  {
    path: "/firma/",
    component: () => import("@/views/Company"),
    meta: {
      authRequired: true,
      authorize: ["Company"],
    },
    beforeEnter: async (to, from, next) => {
      const valid = isCompany();
      if (valid) {
        next();
      } else {
        next("/startseite");
      }
    },
    children: [
      {
        path: "",
        component: () => import("@/views/Common/UserView"),
      },
      {
        path: "Dienstleister",
        component: () => import("@/views/Company/ServiceProviderView"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "Zeitplan",
        component: () => import("@/views/Company/ProviderScheduleView"),
        meta: {
          authRequired: true,
        },
      },
    ],
  },
  {
    path: "/kunde/",
    component: () => import("@/views/Customer"),
    meta: {
      authRequired: true,
      authorize: ["Customer", "MasterHand"],
    },
    beforeEnter: async (to, from, next) => {
      const valid = isCustomer() || isMaster();
      if (valid) {
        next();
      } else {
        next("/startseite");
      }
    },
    children: [
      {
        path: "",
        component: () => import("@/views/Common/UserView"),
      },
    ],
  },
  {
    path: "*",
    redirect: "/startseite",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  await authGuard(to, from, next);
});

export default router;
