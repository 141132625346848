const getters = {
  sub: (state) => {
    return state.signInState.sub;
  },
  email: (state) => {
    return state.signInState.email;
  },
  name: (state) => {
    return state.signInState.name;
  },
  sis: (state) => {
    return state.signInState;
  },
  isAuthenticated: (state) => {
    return state.signInState.token;
  },
  isConsumer: (state) => {
    return state.signInState.roles.includes("Consumer");
  },
  isCompany: (state) => {
    return (
      state.signInState.roles && state.signInState.roles.includes("Company")
    );
  },
  isAdmin: (state) => {
    return (
      state.signInState.roles &&
      (state.signInState.roles.includes("Admin") ||
        state.signInState.roles.includes("Developer"))
    );
  },
  isDev: (state) => {
    return (
      state.signInState.roles && state.signInState.roles.includes("Developer")
    );
  },
};

export default getters;
