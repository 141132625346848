<template>
  <div></div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "HomeView",

  computed: {
    ...mapGetters("authModule", {
      uid: "sub",
      name: "name",
    }),
  },

  async mounted() {
    await this.localStorageLogin();
  },

  methods: {
    ...mapActions("authModule", ["useLocalStorageTokenToSignInAction"]),

    async localStorageLogin() {
      await this.useLocalStorageTokenToSignInAction();
    },
  },
};
</script>
