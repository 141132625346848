import api from "@/api/api-config";

export async function getPersonalInfo(userId) {
  return await api.get(`personal-info/${userId}`);
}

export async function createPersonalInfo(data) {
  return await api.post(`personal-info`, data);
}

export async function updatePersonalInfo(data) {
  return await api.put(`personal-info`, data);
}
