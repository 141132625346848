import * as types from "./types";
import {
  getSchedule,
  setSchedule,
  unsetSchedule,
  getCurrentDaySchedule,
} from "./services";

export async function getScheduleAction({ commit }, payload) {
  commit(types.PROCESSING_SCHEDULE, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await getSchedule(payload);
    commit(types.GET_SCHEDULE, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_SCHEDULE, false);
  }

  return state;
}

export async function getCurrentDayScheduleAction({ commit }, payload) {
  commit(types.PROCESSING_SCHEDULE, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await getCurrentDaySchedule(
      payload.providerId,
      payload.dayNumber
    );
    if (data.serviceProviderId == 0) {
      data.serviceProviderId = payload.providerId;
      data.dayNumber = payload.dayNumber;
    }
    commit(types.GET_CURRENT_DAY_SCHEDULE, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_SCHEDULE, false);
  }

  return state;
}

export async function setScheduleAction({ commit }, payload) {
  commit(types.PROCESSING_SCHEDULE, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await setSchedule(payload);
    commit(types.SET_SCHEDULE, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_SCHEDULE, false);
  }

  return state;
}

export async function unsetScheduleAction({ commit }, payload) {
  commit(types.PROCESSING_SCHEDULE, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await unsetSchedule(payload);
    commit(types.UNSET_SCHEDULE, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_SCHEDULE, false);
  }

  return state;
}
