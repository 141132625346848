import * as types from "./types";

const mutations = {
  [types.PROCESSING_PINFO](state, value) {
    state.processingInfo = value;
  },
  [types.GET_PINFO](state, value) {
    state.personalInfo = value;
  },
  [types.CREATE_PINFO](state, value) {
    state.personalInfo = value;
  },
  [types.UPDATE_PINFO](state, value) {
    state.personalInfo = value;
  },
};

export default mutations;
