const state = {
  signInState: {
    email: "",
    exp: Date.now(),
    sub: "",
    name: null,
    roles: null,
    token: null,
  },
};

export default state;
