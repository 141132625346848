import * as types from "./types";
import {
  getProviders,
  createProvider,
  updateProvider,
  deleteProvider,
  getProvider,
  getProviderByUser,
} from "./services";

export async function getProvidersAction({ commit }) {
  commit(types.PROCESSING_PROVIDERS, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await getProviders();
    commit(types.GET_SERVICE_PROVIDERS, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_PROVIDERS, false);
  }

  return state;
}

export async function getProviderAction({ commit }, payload) {
  commit(types.PROCESSING_PROVIDER, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await getProvider(payload);
    commit(types.GET_SERVICE_PROVIDER, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_PROVIDER, false);
  }

  return state;
}

export async function getProviderByUserAction({ commit }, payload) {
  commit(types.PROCESSING_PROVIDER, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await getProviderByUser(payload);
    commit(types.GET_SERVICE_PROVIDER, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_PROVIDER, false);
  }

  return state;
}

export async function createProviderAction({ commit }, payload) {
  commit(types.PROCESSING_PROVIDER, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await createProvider(payload);
    commit(types.CREATE_SERVICE_PROVIDER, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_PROVIDER, false);
  }

  return state;
}

export async function updateProviderAction({ commit }, payload) {
  commit(types.PROCESSING_PROVIDER, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await updateProvider(payload);
    commit(types.UPDATE_SERVICE_PROVIDER, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_PROVIDER, false);
  }

  return state;
}

export async function deleteProviderAction({ commit }, payload) {
  commit(types.PROCESSING_PROVIDER, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await deleteProvider(payload);
    commit(types.DELETE_SERVICE_PROVIDER, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_PROVIDER, false);
  }

  return state;
}
