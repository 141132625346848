import * as types from "./types";
import {
  getPersonalInfo,
  createPersonalInfo,
  updatePersonalInfo,
} from "./services";

export async function getPersonalInfoAction({ commit }, payload) {
  commit(types.PROCESSING_PINFO, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await getPersonalInfo(payload);
    commit(types.GET_PINFO, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_PINFO, false);
  }

  return state;
}

export async function createPersonalInfoAction({ commit }, payload) {
  commit(types.PROCESSING_PINFO, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await createPersonalInfo(payload);
    commit(types.CREATE_PINFO, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_PINFO, false);
  }

  return state;
}

export async function updatePersonalInfoAction({ commit }, payload) {
  commit(types.PROCESSING_PINFO, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await updatePersonalInfo(payload);
    commit(types.UPDATE_PINFO, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING_PINFO, false);
  }

  return state;
}
