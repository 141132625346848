import * as types from "./types";

const mutations = {
  [types.PROCESSING_USERS](state, value) {
    state.processingUsers = value;
  },
  [types.GET_USERS_DATA](state, value) {
    state.users = value;
  },
  [types.GET_COMPANY_USERS_DATA](state, value) {
    state.companies = value;
  },
  [types.PROCESSING_USER](state, value) {
    state.processingUser = value;
  },
  [types.GET_USER_DATA](state, value) {
    state.user = value;
  },
  [types.UPDATE_USER](state, value) {
    state.user = value;
    let idx = state.users
      .map(function (e) {
        return e.id;
      })
      .indexOf(value.id);
    state.users.splice(idx, 1);
    state.users.push(Object.assign({}, value));
  },
  [types.CREATE_USER](state, value) {
    state.user = value;
    let copy = Object.assign({}, value);
    state.users.push(copy);
  },
  [types.REGISTER_USER](state, value) {
    state.user = value;
  },
  [types.CONFIRM_USER_EMAIL](state, value) {
    if (value) {
      let copy = Object.assign({}, state.user);
      if (state.users) state.users.push(copy);
    }
  },
  [types.DELETE_USER](state, value) {
    state.user = value;
    let idx = state.users
      .map(function (e) {
        return e.id;
      })
      .indexOf(value.id);
    state.users.splice(idx, 1);
  },
};

export default mutations;
