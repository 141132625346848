import * as types from "./types";
import { getData } from "./services";

export async function getDataAction({ commit }) {
  commit(types.PROCESSING, true);

  let state = {
    success: false,
    message: null,
  };
  try {
    const { data } = await getData();
    commit(types.GET_DATA, data);
    state.success = true;
  } catch (e) {
    console.error(e);
    state.message = e;
  } finally {
    commit(types.PROCESSING, false);
  }

  return state;
}
