import api from "@/api/api-config";

export async function getProviders() {
  return await api.get(`service-provider`);
}

export async function getProvider(providerId) {
  return await api.get(`service-provider/${providerId}`);
}

export async function getProviderByUser(userId) {
  return await api.get(`service-provider/by-user/${userId}`);
}

export async function createProvider(data) {
  return await api.post(`service-provider`, data);
}

export async function updateProvider(data) {
  return await api.put(`service-provider`, data);
}

export async function deleteProvider(providerId) {
  return await api.delete(`service-provider/${providerId}`);
}
