import api from "@/api/api-config";

export async function getServices(serviceProviderId) {
  return await api.get(`beauty-service-provided/${serviceProviderId}`);
}

export async function getService(serviceId) {
  return await api.get(`beauty-service-provided/single/${serviceId}`);
}

export async function createService(data) {
  return await api.post(`beauty-service-provided`, data);
}

export async function updateService(data) {
  return await api.put(`beauty-service-provided`, data);
}

export async function deleteService(serviceId) {
  return await api.delete(`beauty-service-provided/${serviceId}`);
}
