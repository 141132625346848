import api from "@/api/api-config";
import { jwtDecode } from "jwt-decode";

export async function loginUser(login) {
  return await api.post("token", login);
}

export async function validateToken(token) {
  return await api.get(`token/${token}`);
}

const key = "token";

export function getToken() {
  return localStorage.getItem(key);
}

export function logOut() {
  localStorage.clear();
  window.location = "/login";
}

export async function isTokenFromLocalStorageValid() {
  const token = localStorage.getItem(key);
  if (!token) {
    return false;
  }

  const { data } = await validateToken(token);
  if (!data.isValid) {
    return false;
  }

  const decoded = jwtDecode(token);
  const expiresAt = decoded.exp * 1000;
  const dateNow = Date.now();
  return dateNow < expiresAt;
}

export function getUserEmailFromToken() {
  const token = localStorage.getItem(key);
  if (!token) return false;
  const decoded = jwtDecode(token);
  return decoded.email;
}

export function isCustomer() {
  const token = localStorage.getItem(key);
  if (!token) return false;
  const decoded = jwtDecode(token);
  return decoded.roles && decoded.roles.indexOf("Customer") !== -1;
}

export function isMaster() {
  const token = localStorage.getItem(key);
  if (!token) return false;
  const decoded = jwtDecode(token);
  return decoded.roles && decoded.roles.indexOf("MasterHand") !== -1;
}

export function isCompany() {
  const token = localStorage.getItem(key);
  if (!token) return false;
  const decoded = jwtDecode(token);
  return decoded.roles && decoded.roles.indexOf("Company") !== -1;
}

export function isAdmin() {
  const token = localStorage.getItem(key);
  if (!token) return false;
  const decoded = jwtDecode(token);
  return (
    decoded.roles &&
    (decoded.roles.indexOf("Admin") !== -1 ||
      decoded.roles.indexOf("Developer") !== -1)
  );
}

export function authRoute(from, to, next) {
  if (isCustomer() || isMaster()) {
    next("/kunde/");
  } else if (isCompany()) {
    next("/firma/");
  } else if (isAdmin()) {
    next("/admin/");
  } else {
    next();
  }
}
