import api from "@/api/api-config";

export async function getSchedule(providerId) {
  return await api.get(`work-schedule/${providerId}`);
}

export async function getCurrentDaySchedule(providerId, dayNumber) {
  return await api.get(`work-schedule/${providerId}/${dayNumber}`);
}

export async function setSchedule(data) {
  return await api.post(`work-schedule`, data);
}

export async function unsetSchedule(itemId) {
  return await api.delete(`work-schedule/${itemId}`);
}
