import api from "@/api/api-config";

export async function getTermins(providerId, dateStart, dateEnd) {
  if (!dateEnd) return await api.get(`termin/${providerId}/${dateStart}`);
  return await api.get(`termin/${providerId}/${dateStart}/${dateEnd}`);
}

export async function getTermin(terminId) {
  return await api.get(`termin/${terminId}`);
}

export async function createTermin(data) {
  return await api.post(`termin`, data);
}

export async function updateTermin(data) {
  return await api.put(`termin`, data);
}

export async function deleteTermin(terminId) {
  return await api.delete(`termin/${terminId}`);
}
